
.maintenance-container {
  font-family: 'Arial', sans-serif;
  background-color: #f5f5f5;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.content {
  text-align: center;
  background-color: #fff;
  padding: 50px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.icon {
  width: 150px;
  margin-bottom: 30px;
}

h1 {
  font-size: 2.5rem;
  color: #007bff;
  margin-bottom: 20px;
}

p {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 30px;
}

.timer {
  font-size: 1.5rem;
  font-weight: bold;
  color: #ff0000;
}

@media (max-width: 768px) {
  .maintenance-container {
    padding: 30px;
  }

  h1 {
    font-size: 2rem;
  }

  p {
    font-size: 1rem;
  }
}
